import { useMemo, useState } from 'react';
import { Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AxiosResponse } from 'axios';
import linkifyHtml from 'linkify-html';
import { isEqual, keys, pick } from 'lodash-es';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import ModalHeaderCloseButton from 'platform/common/components/Modal/ModalHeaderCloseButton';
import RoundIconButton from 'platform/common/components/RoundIconButton/RoundIconButton';
import Spinner from 'platform/common/components/Spinner/Spinner';
import TextEditor from 'platform/common/components/TextEditor/TextEditor';
import BorderlessUserSelect from 'platform/common/components/UserSelect/BorderlessUserSelect';
import ErrorBoundary from 'platform/common/containers/ErrorBoundary/ErrorBoundary';
import useToggle from 'platform/common/hooks/useToggle';
import { updateById } from 'platform/common/utils/array.util';
import Switch from 'platform/formik/Switch/Switch';
import TaskChangesContainer from 'platform/task/ChangeLog/TaskChangesContainer';
import TaskBlockedBadge from 'platform/task/Common/TaskBlockedBadge';
import TaskStatusIcon from 'platform/task/Common/TaskStatusIcon';
import { attachmentReducer } from 'platform/task/reducers/attachmentReducer';
import { isTaskBlocked, isTaskFinished } from 'platform/task/task.util';
import { User } from 'platform/userManagement/types/user.type';
import { subtaskReducer } from '../reducers/subtaskReducer';
import {
    Task,
    TaskBoardChange,
    TaskBoardState,
    TaskCreationData,
    TaskData,
    TaskLane,
    TaskLocation,
} from '../task.types';
import KanbanAttachmentsEdit from './KanbanAttachmentsEdit';
import KanbanBlockedByList from './KanbanBlockedByList';
import KanbanCardActionsDropdown from './KanbanCardActionsDropdown';
import KanbanCommentList from './KanbanCommentList';
import KanbanDates from './KanbanDates';
import KanbanLabelEditor from './KanbanLabelEditor';
import KanbanNotificationIconButton from './KanbanNotificationIconButton';
import KanbanSubCardList from './KanbanSubCardList';
import KanbanTimestamp from './KanbanTimestamp';
import KanbanWatchers from './KanbanWatchers';
import styles from './KanbanCardModal.scss';

type Props = {
    board: TaskBoardState;
    task: Task;
    parentTask?: Task;
    lane: TaskLane;
    canRenameLabels: boolean;
    userOptions: User[];
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<TaskBoardChange[]>;
    onTaskUpdate: (taskId: number, data: Partial<TaskData>) => Promise<TaskBoardChange[]>;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
    onTaskMove: (taskId: number, location: TaskLocation) => Promise<TaskBoardChange[]>;
    onTaskDelete: (taskId: number) => Promise<TaskBoardChange[]>;
    onAttachmentUpload: (taskId: number, name: string, content: Blob) => Promise<TaskBoardChange[]>;
    onAttachmentDelete: (taskId: number, attachmentId: number) => Promise<TaskBoardChange[]>;
    onAttachmentDownload: (taskId: number, attachmentId: number) => Promise<AxiosResponse<Blob>>;
    onLabelUpdate: (key: string, name: string) => Promise<void>;
    onTaskOpen: (taskId: number) => void;
    onClose: () => void;
    onTaskCopy: (task: Task) => void;
};

const KanbanCardModal = ({
    board,
    task: initialTask,
    parentTask,
    lane,
    canRenameLabels,
    userOptions,
    onTaskAdd,
    onTaskUpdate,
    onTaskWatchChange,
    onTaskMove,
    onTaskDelete,
    onAttachmentUpload,
    onAttachmentDelete,
    onAttachmentDownload,
    onLabelUpdate,
    onTaskOpen,
    onTaskCopy,
    onClose,
}: Props) => {
    const [updating, setUpdating] = useState(false);
    const [task, setTask] = useState(initialTask);
    const [nameInput, setNameInput] = useState(task.name);
    const [descriptionInput, setDescriptionInput] = useState(task.description);
    const [showChanges, toggleShowChanges] = useToggle(false);

    const laneOptions = useMemo(
        () =>
            board.lanes.map((op) => ({
                label: op.name,
                value: op.id,
                action: () => onTaskMove(task.id, { laneId: op.id, position: 0 }),
            })),
        [board.lanes, onTaskMove, task.id]
    );

    const currentLaneLabel = useMemo(
        () => laneOptions.find((op) => op.value === lane.id)?.label,
        [lane.id, laneOptions, task.id]
    );

    const taskIndex = lane.tasks.findIndex((t) => t.id === task.id);
    const totalTasksInLane = lane.tasks.length;

    const applyChanges = (changes: TaskBoardChange[]) =>
        setTask(
            changes.reduce(
                (t, change) => (change.type === 'TASK_UPDATED' ? { ...t, ...change.details?.data } : t),
                task
            )
        );

    const handleUpdate = async (data: Partial<TaskData>) => {
        const previousData = pick(task, keys(data));
        if (!isEqual(data, previousData)) {
            setUpdating(true);
            setTask({ ...task, ...data });
            applyChanges(await onTaskUpdate(task.id, data));
            setUpdating(false);
        }
    };

    const handleLabelRename = async (key: string, name: string) => {
        setUpdating(true);
        await onLabelUpdate(key, name);
        setUpdating(false);
    };

    const handleNameBlur = () => {
        if (nameInput) {
            handleUpdate({ name: nameInput });
        } else {
            setNameInput(task.name);
        }
    };

    const handleDescriptionBlur = () => {
        if (descriptionInput !== task.description) {
            const description = linkifyHtml((descriptionInput ?? '').trim());
            setDescriptionInput(description);
            handleUpdate({ description });
        }
    };

    const handleWatchChange = async (watch: boolean) => {
        setUpdating(true);
        applyChanges(await onTaskWatchChange(task.id, watch));
        setUpdating(false);
    };

    const markSubtaskSyncing = (subtaskId: number, syncing: boolean) => {
        setTask({
            ...task,
            subtasks: updateById(task.subtasks ?? [], subtaskId, (subtask) => ({ ...subtask, syncing })),
        });
    };

    const applySubtaskChanges = (changes: TaskBoardChange[]) => {
        const subtasks = changes.reduce(subtaskReducer, task.subtasks ?? []);
        setTask({ ...task, subtasks });
    };

    const changeSubtasks = async (subtaskId: number | undefined, request: Promise<TaskBoardChange[]>) => {
        if (subtaskId) markSubtaskSyncing(subtaskId, true);
        applySubtaskChanges(await request);
    };

    const applyAttachmentChanges = (changes: TaskBoardChange[]) => {
        const attachments = changes.reduce(attachmentReducer, task.attachments ?? []);
        setTask({ ...task, attachments });
    };

    return (
        <Modal isOpen toggle={onClose} className="Kanban-card-modal">
            <ErrorBoundary>
                <ModalHeader>
                    <div className="d-flex align-items-center">
                        {parentTask && (
                            <>
                                <LabelLink className="text-muted" onClick={() => onTaskOpen(parentTask!.id)}>
                                    {parentTask.name}
                                </LabelLink>
                                <div className="text-muted fw-bold ps-1 me-3">/ {task.name}</div>
                            </>
                        )}
                        {updating && <Spinner size="sm" />}
                        <div className="d-flex flex-grow-1 justify-content-end align-items-center">
                            <BorderlessUserSelect
                                userIds={task.assigneeIds ?? []}
                                options={userOptions}
                                onChange={(assigneeIds) => handleUpdate({ assigneeIds })}
                            />
                            <KanbanNotificationIconButton
                                className="RoundIconButton"
                                notifiedUserIds={task.notifiedUserIds}
                                onChange={handleWatchChange}
                            />
                            {!parentTask && (
                                <ButtonDropdown
                                    className="Kanban-button-dropdown ms-2"
                                    caret
                                    items={laneOptions.filter((op) => op.value !== lane.id)}
                                >
                                    {currentLaneLabel}
                                </ButtonDropdown>
                            )}
                            <KanbanCardActionsDropdown
                                className="ms-2"
                                boardId={board.id}
                                task={task}
                                currentPosition={taskIndex}
                                bottomPosition={totalTasksInLane}
                                onMove={(position) => onTaskMove(task.id, { laneId: lane.id, position })}
                                onUpdate={handleUpdate}
                                onDelete={() => {
                                    onTaskDelete(task.id);
                                    onClose();
                                }}
                                onTaskCopy={taskIndex !== -1 ? onTaskCopy : undefined}
                            />
                            <ModalHeaderCloseButton onClick={onClose} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        {task.status !== 'ACTIVE' && <TaskStatusIcon status={task.status} />}
                        {!isTaskFinished(task, lane) && isTaskBlocked(task, board.lanes) && (
                            <TaskBlockedBadge />
                        )}
                        <Input
                            name="name"
                            value={nameInput}
                            placeholder="Name"
                            onChange={(e) => setNameInput(e.target.value)}
                            onBlur={handleNameBlur}
                            onKeyPress={(e) => {
                                if (e.code === 'Enter') {
                                    (e.target as HTMLInputElement).blur();
                                }
                            }}
                        />
                    </div>
                </ModalHeader>
                <ModalBody className="p-4">
                    <TextEditor
                        onKeyDown={(e) => {
                            if (e.code === 'Escape') {
                                onClose();
                            }
                        }}
                        autoFocus
                        autoResize
                        minHeight={200}
                        value={descriptionInput}
                        backgroundColor={styles.inputBackgroundColor}
                        onChange={setDescriptionInput}
                        onBlur={handleDescriptionBlur}
                    />
                    {!parentTask && (
                        <div className="d-flex align-items-center mt-2 ms-auto">
                            <Switch
                                className="me-2"
                                value={!!task.display?.showDescriptionOnCard}
                                onChange={({ target }) =>
                                    handleUpdate({
                                        display: { ...task.display, showDescriptionOnCard: target.checked },
                                    })
                                }
                            />
                            <span className="text-muted">Show description on small card</span>
                        </div>
                    )}
                    {!parentTask && (
                        <KanbanLabelEditor
                            labelKeys={task.labelKeys ?? []}
                            labelNames={board.labelNames ?? {}}
                            onRename={canRenameLabels ? handleLabelRename : undefined}
                            onChange={(labelKeys) => handleUpdate({ labelKeys })}
                        />
                    )}
                    <hr className="Kanban-separator" />
                    <KanbanDates
                        startDate={task.startDate}
                        dueDate={task.dueDate}
                        tentativeDates={task.tentativeDates}
                        onChange={handleUpdate}
                    />
                    <hr className="Kanban-separator" />
                    <KanbanAttachmentsEdit
                        attachments={task.attachments ?? []}
                        onUpload={(name, content) =>
                            onAttachmentUpload(task.id, name, content).then(applyAttachmentChanges)
                        }
                        onDelete={(id) => onAttachmentDelete(task.id, id).then(applyAttachmentChanges)}
                        onDownload={(id) => onAttachmentDownload(task.id, id)}
                    />
                    <hr className="Kanban-separator" />
                    {!parentTask && (
                        <>
                            <KanbanSubCardList
                                boardId={board.id}
                                subtasks={task.subtasks ?? []}
                                userOptions={userOptions}
                                onOpen={onTaskOpen}
                                onAdd={(position, data) =>
                                    changeSubtasks(
                                        undefined,
                                        onTaskAdd({ parentTaskId: task.id, position }, data)
                                    )
                                }
                                onMove={(subtaskId, position) =>
                                    changeSubtasks(
                                        subtaskId,
                                        onTaskMove(subtaskId, { parentTaskId: task.id, position })
                                    )
                                }
                                onUpdate={(subtaskId, data) =>
                                    changeSubtasks(subtaskId, onTaskUpdate(subtaskId, data))
                                }
                                onDelete={(subtaskId) => changeSubtasks(subtaskId, onTaskDelete(subtaskId))}
                                onTaskWatchChange={onTaskWatchChange}
                            />
                            <hr className="Kanban-separator" />
                            <KanbanBlockedByList
                                task={task}
                                lanes={board.lanes}
                                userOptions={userOptions}
                                onOpen={onTaskOpen}
                                onChange={(blockedBy) => handleUpdate({ blockedBy })}
                            />
                            <hr className="Kanban-separator" />
                            <KanbanCommentList boardId={board.id} taskId={task.id} users={userOptions} />
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <KanbanWatchers task={task} userOptions={userOptions} />
                    <div className="d-flex align-items-center">
                        <div className="text-muted">
                            <b className="me-2 opacity-75">Created:</b>
                            <KanbanTimestamp date={task.createdOn} />
                            <span className="opacity-50 ms-2">by {task.createdBy}</span>
                        </div>
                        <div className="text-muted ms-4">
                            <b className="me-2 opacity-75">Last changed:</b>
                            <KanbanTimestamp date={task.updatedOn} />
                            <span className="opacity-50 ms-2">by {task.updatedBy}</span>
                        </div>
                        <RoundIconButton
                            className="ms-auto"
                            icon="fa fa-history"
                            onClick={toggleShowChanges}
                        />
                    </div>
                    {showChanges && (
                        <TaskChangesContainer
                            className="mt-4"
                            board={board}
                            userOptions={userOptions}
                            taskId={task.id}
                        />
                    )}
                </ModalFooter>
            </ErrorBoundary>
        </Modal>
    );
};

export default KanbanCardModal;
