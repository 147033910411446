import { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { exportedFilename } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useDefaultMetrics } from 'platform/analytics/hooks/useDefaultMetrics';
import InsightReportTable from 'platform/analytics/reportComponents/InsightReportTable';
import ReportPivotTable from 'platform/analytics/reportComponents/ReportTableContainer/ReportPivotTable';
import ReportTable from 'platform/analytics/reportComponents/ReportTableContainer/ReportTable';
import ConditionalWrapper from 'platform/common/components/ConditionalWrapper/ConditionalWrapper';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { ExportAction } from 'platform/common/components/DataExportButton/DataExportButton';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import paginate from 'platform/common/utils/paginate.util';
import { OlapReport, PivotReport, TableState } from '../../analytics.types';
import { getComponentInteractions, resolveCustomFilters } from '../useReport';
import { ReportAiSummaryContainerProps } from './ReportAiSummaryContainer/ReportAiSummaryContainer';
import ReportTableAiSummaryBtn from './ReportTableAiSummaryBtn';
import ReportTableFormContainer from './ReportTableForm/ReportTableFormContainer';
import ReportTableTopToolbar from './ReportTableTopToolbar';
import { addDependentDimensions } from './columnBuilders';
import { useReportTableContainer } from './useReportTableContainer';

export interface TableProps {
    Toolbar?: FunctionComponent<{ afterUpdate: () => void; componentState: TableState }>;
    exportActions?: ExportAction[];
}

const ReportTableContainer = ({
    reportName,
    componentState,
    Toolbar,
    exportActions,
    editMode,
    summaryProps,
}: {
    reportName: string;
    componentState: TableState;
    editMode: boolean;
    summaryProps?: ReportAiSummaryContainerProps;
} & TableProps) => {
    const analyticsSettings = useSelector(analyticsSelectors.settings);
    const hasInlineActions = useFeature('ANALYTICS_INLINE_ACTIONS');
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const filters = useSelector(analyticsSelectors.filters);
    const { definitions } = useAnalyticsMetadata();
    const { includeVatRate, modelOptIn, components } = useSelector(analyticsSelectors.settings);
    const compareType = useSelector(analyticsSelectors.compareType);
    const showConfirmationModal = useConfirmationModal();
    const activePreset = componentState.presets[componentState.activePresetIndex];
    const { dimensions, metrics, pivot, hideEmptyColumns, editableDimensions, customFilters } = activePreset;
    const sort = activePreset.sort ?? componentState.sort ?? [];
    const isForm = !!analyticsSettings.inlineEditing && !!editableDimensions?.length && !editMode;
    const exportName = exportedFilename(reportName, activePreset.name, periods);
    const advertiserIds = (filters.find((f) => f.key === 'advertiser_id')?.values as number[]) || [];
    const [isTableCollapsed, setIsTableCollapsed] = useState<boolean>(componentState.collapseView ?? false);

    const {
        template,
        redirectToDataExplorer,
        changePage,
        changePageSize,
        changeSorting,
        removeColumn,
        changeColumnColouring,
        error,
        report,
        compareReport,
        refetchReport,
        loading,
        compatibleMetrics,
    } = useReportTableContainer(componentState);

    useDefaultMetrics({ componentState, compatibleMetrics, compatibleMetricsLoading: loading });

    useEffect(() => {
        if (componentState.pageNumber === 0) {
            return;
        }

        changePage(0);
    }, [periods, filters, activePreset, componentState.pageSize, componentState.collapseView]);

    const containerStyle: CSSProperties = componentState.height
        ? { flex: '1 0 auto', height: 0, minHeight: componentState.height }
        : {};

    const topToolbar = (
        <ReportTableTopToolbar
            isTableCollapsed={isTableCollapsed}
            setIsTableCollapsed={setIsTableCollapsed}
            Toolbar={Toolbar && <Toolbar afterUpdate={refetchReport} componentState={componentState} />}
            componentState={componentState}
        />
    );

    const handleExport = () =>
        paginate(
            async (number, size) =>
                generateOlapReport({
                    templateId: template.id,
                    dimensions: addDependentDimensions(dimensions, template),
                    metrics,
                    from: periods?.primaryFrom,
                    to: periods?.primaryTo,
                    dimensionFilters: [
                        ...filters,
                        ...(await resolveCustomFilters(customFilters, definitions.filters, advertiserIds)),
                    ],
                    paging: {
                        pageNumber: number,
                        pageSize: size,
                    },
                    sort,
                    includeVatRate,
                    modelOptIn,
                    interactions: getComponentInteractions(components),
                }),
            (r) => r.rows
        );

    const onColumnRemoveClick = (column: TableColumn) => {
        showConfirmationModal(
            () => {
                if (column.id) {
                    removeColumn(column.id);
                }
            },
            {
                title: 'Remove column',
                text: `"${column.HeaderText}" column will be removed, and the table will be reloaded.
                    You can add the column back by adjusting Report settings.`,
                okLabel: 'Remove column',
            }
        );
    };

    if (loading)
        return (
            <FormattedTable
                containerClass="shadow-none"
                topToolbar={componentState.summaryTable ? undefined : topToolbar}
                manual
                columns={[]}
                data={[]}
                loading
            />
        );

    const topRightToolbar =
        report?.type === 'DEFAULT' && !isTableCollapsed ? (
            <ReportTableAiSummaryBtn
                report={report}
                compareReport={compareReport as OlapReport}
                summaryProps={summaryProps}
            />
        ) : undefined;

    return (
        <ConditionalWrapper
            shouldRenderWrapper={isForm}
            Wrapper={ReportTableFormContainer}
            afterSubmit={() => refetchReport()}
            defaultValues={report?.rows ?? []}
        >
            {!!error && (
                <FormattedTable
                    topToolbar={topToolbar}
                    columns={[]}
                    data={[]}
                    containerClass="h-100"
                    NoDataComponent={() => <ErrorMessage error={error} onRetry={refetchReport} />}
                />
            )}
            {!!report && (
                <div className="position-relative">
                    {report.type === 'DEFAULT' && (
                        <ReportTable
                            visible={!isTableCollapsed}
                            topRightToolbar={topRightToolbar}
                            id={componentState.id}
                            template={template}
                            dimensionFilters={filters}
                            dimensions={dimensions}
                            metrics={metrics}
                            editableDimensions={editableDimensions ?? []}
                            report={report}
                            compareReport={compareReport as OlapReport}
                            compareWith={compareType}
                            TopToolbar={topToolbar}
                            sort={sort}
                            pageNumber={componentState.pageNumber}
                            pageSize={componentState.pageSize}
                            summaryTable={componentState.summaryTable}
                            hasInlineActions={hasInlineActions}
                            hideEmptyColumns={hideEmptyColumns}
                            definitions={definitions}
                            exportActions={exportActions}
                            columnsColouring={componentState.columnsColouring}
                            containerStyle={containerStyle}
                            editMode={editMode}
                            exportName={exportName}
                            refetchReport={refetchReport}
                            onDataExplorerClick={redirectToDataExplorer}
                            onDataExport={handleExport}
                            onPageChange={changePage}
                            onPageSizeChange={changePageSize}
                            onSortChange={changeSorting}
                            onColumnRemove={onColumnRemoveClick}
                            onColumnColouringChange={changeColumnColouring}
                        />
                    )}
                    {report.type === 'INSIGHT' && (
                        <InsightReportTable
                            visible={!isTableCollapsed}
                            report={report}
                            compareReport={compareReport}
                            compareWith={compareType}
                            TopToolbar={topToolbar}
                            dimensions={dimensions}
                            metrics={metrics}
                            pivot={pivot}
                            columnsColouring={componentState.columnsColouring}
                            sort={sort}
                            pageSize={componentState.pageSize}
                            pageNumber={componentState.pageNumber}
                            summaryTable={componentState.summaryTable}
                            containerStyle={containerStyle}
                            exportName={exportName}
                            definitions={definitions}
                            onPageChange={changePage}
                            onPageSizeChange={changePageSize}
                            onSortChange={changeSorting}
                            onColumnRemove={onColumnRemoveClick}
                            onColumnColouringChange={changeColumnColouring}
                        />
                    )}
                    {report.type === 'PIVOT' && pivot && (
                        <ReportPivotTable
                            visible={!isTableCollapsed}
                            template={template}
                            dimensions={dimensions}
                            metrics={metrics}
                            report={report}
                            compareReport={compareReport as PivotReport}
                            TopToolbar={topToolbar}
                            pivot={pivot}
                            sort={sort}
                            pageSize={componentState.pageSize}
                            pageNumber={componentState.pageNumber}
                            summaryTable={componentState.summaryTable}
                            containerStyle={containerStyle}
                            exportName={exportName}
                            onDataExplorerClick={redirectToDataExplorer}
                            onPageChange={changePage}
                            onPageSizeChange={changePageSize}
                            onSortChange={changeSorting}
                        />
                    )}
                </div>
            )}
        </ConditionalWrapper>
    );
};

export default ReportTableContainer;
