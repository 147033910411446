import { FormGroup, Input, InputGroup, InputGroupText, Label, TabContent, TabPane } from 'reactstrap';
import { EDITABLE_DIMENSIONS } from 'platform/analytics/analytics.constants';
import {
    ComponentSettings,
    DimensionKey,
    ReportFilterWithOperator,
    TablePreset,
    TableState,
} from 'platform/analytics/analytics.types';
import {
    customFiltersToReportFilters,
    isOlapReport,
    reportFiltersToCustomFilters,
} from 'platform/analytics/analytics.util';
import ReportCustomDateRange from 'platform/analytics/reportComponents/ReportCustomDateRange';
import ReportCustomFilters from 'platform/analytics/reportComponents/ReportFilters/ReportCustomFilters';
import ReportPaging from 'platform/analytics/reportComponents/ReportFilters/ReportPaging';
import ReportSorting from 'platform/analytics/reportComponents/ReportFilters/ReportSorting';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import ReportTableTabs from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableTabs';
import TemplateSelect from 'platform/analytics/reportComponents/TemplateSelect';
import { arrayItemUpdate } from 'platform/common/utils/array.util';
import '../reportComponents.scss';

const ReportTableSettings = ({
    componentState,
    template,
    compatibleFilters,
    dimensionOptions,
    metricOptions,
    filterOptionsMap,
    loading,
    filtersLoading,
    canEdit,
    onChange,
    onFiltersChange,
}: ComponentSettings<TableState>) => {
    const activeTab = componentState.activePresetIndex;

    const handlePresetChange = (index: number, changes: Partial<TablePreset>) => {
        onChange({
            ...componentState,
            presets: arrayItemUpdate(componentState.presets, index, {
                ...componentState.presets[index],
                ...changes,
            }),
        });
    };

    const handleFiltersChange = async (index: number, filters: ReportFilterWithOperator[]) => {
        const resolvedFilters = await onFiltersChange(filters);
        handlePresetChange(index, {
            customFilters: reportFiltersToCustomFilters(resolvedFilters),
        });
    };

    const getSelectedColumns = (preset: TablePreset) => [
        ...template.dimensions.filter((d) => preset.dimensions.includes(d.key)),
        ...template.metrics.filter((m) => preset.metrics.includes(m.key)),
    ];

    return (
        <>
            <hr />
            <ReportTableTabs componentState={componentState} onChange={onChange} />
            <TabContent activeTab={activeTab} className="mb-3 border-end-0 border-start-0 border-bottom-0">
                {componentState.presets.map((preset, index) => (
                    <TabPane key={index} tabId={index} className="px-0 pb-0">
                        <TemplateSelect
                            templateId={preset.templateId}
                            canEdit={canEdit}
                            onChange={(newTemplate) => {
                                handlePresetChange(index, {
                                    templateId: newTemplate.id,
                                    metrics: preset.metrics.filter((m) =>
                                        newTemplate.metrics.find((metric) => metric.key === m)
                                    ),
                                    dimensions: preset.dimensions.filter((d) =>
                                        newTemplate.dimensions.find((dimension) => dimension.key === d)
                                    ),
                                    pivot: isOlapReport(newTemplate) ? preset.pivot : undefined,
                                    hideEmptyColumns: isOlapReport(newTemplate)
                                        ? preset.hideEmptyColumns
                                        : false,
                                });
                            }}
                        />
                        <InputGroup className="mb-3">
                            <InputGroupText>Name</InputGroupText>
                            <Input
                                type="text"
                                value={preset.name}
                                onChange={(e) => handlePresetChange(index, { name: e.target.value })}
                            />
                        </InputGroup>
                        <ReportSelectTree
                            name="Dimensions"
                            className="mb-3"
                            options={dimensionOptions}
                            value={preset.dimensions}
                            isLoading={loading}
                            isMulti
                            onChange={(dimensions) => {
                                handlePresetChange(index, {
                                    dimensions,
                                    sort: preset.sort?.filter(
                                        (s) =>
                                            dimensions.includes(s.orderBy) ||
                                            preset.metrics.includes(s.orderBy)
                                    ),
                                });
                            }}
                        />
                        {preset.dimensions.some((d) => EDITABLE_DIMENSIONS.includes(d as DimensionKey)) && (
                            <ReportSelectTree
                                name="Editable dimensions"
                                className="mb-3"
                                isMulti
                                options={template.dimensions
                                    .filter(
                                        (d) =>
                                            EDITABLE_DIMENSIONS.includes(d.key as DimensionKey) &&
                                            preset.dimensions.includes(d.key)
                                    )
                                    .map((o) => ({ ...o, nodes: [] }))}
                                value={preset.editableDimensions}
                                onChange={(editableDimensions: DimensionKey[]) => {
                                    handlePresetChange(index, {
                                        editableDimensions,
                                    });
                                }}
                            />
                        )}
                        <ReportSelectTree
                            name="Metrics"
                            className="mb-3"
                            options={metricOptions}
                            value={preset.metrics}
                            isLoading={loading}
                            isMulti
                            onChange={(metrics) =>
                                handlePresetChange(index, {
                                    metrics,
                                    sort: preset.sort?.filter(
                                        (s) =>
                                            metrics.includes(s.orderBy) ||
                                            preset.dimensions.includes(s.orderBy)
                                    ),
                                })
                            }
                        />
                        <ReportSelectTree
                            name="Pivot by"
                            className="ReportComponent-control-width-medium"
                            options={dimensionOptions}
                            value={preset.pivot?.column}
                            isLoading={loading}
                            isMulti={false}
                            onChange={(column) =>
                                handlePresetChange(index, {
                                    pivot: column ? { ...preset.pivot, column } : undefined,
                                })
                            }
                        />
                        {template.type === 'INSIGHT' && (
                            <>
                                <FormGroup className="mt-3">
                                    <ReportPaging
                                        pageSize={preset.pageSize}
                                        onChange={(pageSize) => handlePresetChange(index, { pageSize })}
                                    />
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <ReportSorting
                                        sorts={preset.sort ?? []}
                                        columns={getSelectedColumns(preset)}
                                        onChange={(sorts) =>
                                            handlePresetChange(index, {
                                                sort: sorts?.length ? sorts : undefined,
                                            })
                                        }
                                    />
                                </FormGroup>
                            </>
                        )}
                        <FormGroup className="mt-3 mb-0">
                            <ReportCustomDateRange
                                customDateRange={preset.customDateRange}
                                onChange={(customDateRange) => handlePresetChange(index, { customDateRange })}
                            />
                        </FormGroup>
                        <FormGroup className="mt-3 mb-0">
                            <ReportCustomFilters
                                template={template}
                                compatibleFilters={compatibleFilters}
                                filters={customFiltersToReportFilters(preset.customFilters)}
                                filterOptionsMap={filterOptionsMap}
                                loading={filtersLoading}
                                onChange={(filters) => handleFiltersChange(index, filters)}
                            />
                        </FormGroup>
                        {isOlapReport(template) && (
                            <FormGroup check className="mt-3 mb-0">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={preset.hideEmptyColumns}
                                        style={{ marginTop: '0.2rem' }}
                                        onChange={(e) =>
                                            handlePresetChange(index, { hideEmptyColumns: e.target.checked })
                                        }
                                    />
                                    Hide empty columns
                                </Label>
                            </FormGroup>
                        )}
                    </TabPane>
                ))}
                <FormGroup check className="mt-2 mb-0">
                    <Label check>
                        <Input
                            type="checkbox"
                            checked={componentState.collapseView}
                            style={{ marginTop: '0.2rem' }}
                            onChange={(e) =>
                                onChange({
                                    ...componentState,
                                    collapseView: e.target.checked,
                                })
                            }
                        />
                        Collapse view
                    </Label>
                </FormGroup>
            </TabContent>
            <hr />
        </>
    );
};

export default ReportTableSettings;
