import { InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import FiltersContainer from 'platform/common/components/FiltersContainer/FiltersContainer';
import Search from 'platform/common/components/Search/Search';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import UserSelect from 'platform/common/components/UserSelect/UserSelect';
import { useUserOptions } from 'platform/common/components/UserSelect/useUserOptions';
import { User } from 'platform/userManagement/types/user.type';
import { LabelNames, TASK_STATUS_OPTIONS, TaskLane, TaskStatus } from '../task.types';
import KanbanColumnSelect from './KanbanColumnSelect';
import KanbanLabelSelect from './KanbanLabelSelect';
import './Kanban.scss';

export interface KanbanFilterValues {
    search: string;
    assignees: {
        operator: 'AND' | 'OR';
        values: number[];
    };
    labels: {
        operator: 'AND' | 'OR';
        values: string[];
    };
    lanes: number[];
    status: TaskStatus[];
    date: [string, string];
}

interface Props {
    labelNames: LabelNames;
    lanes: TaskLane[];
    userOptions: User[];
    filters: KanbanFilterValues;
    onChange: (filter: Partial<KanbanFilterValues>) => void;
}

const KanbanFilters = ({ labelNames, lanes, userOptions, filters, onChange }: Props) => {
    const optionsList = useUserOptions(userOptions);
    return (
        <div className="Kanban-filters">
            <FiltersContainer filtersCount={5} canEdit={false}>
                {({ isFilterHidden }) => (
                    <>
                        <Search
                            value={filters.search}
                            minLength={0}
                            placeholder="Filter by task name..."
                            className={classNames('Kanban-search', {
                                'FiltersContainer-hidden': isFilterHidden(0),
                            })}
                            onSearch={(search) => onChange({ search })}
                        />
                        <InputGroup
                            className={classNames({
                                'FiltersContainer-hidden': isFilterHidden(1),
                            })}
                        >
                            <InputGroupText>Label</InputGroupText>
                            <KanbanLabelSelect
                                labelKeys={filters.labels.values}
                                operator={filters.labels.operator}
                                labelNames={labelNames}
                                onChange={(labels, operator) =>
                                    onChange({
                                        labels: {
                                            values: labels,
                                            operator,
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        <InputGroup
                            className={classNames({
                                'FiltersContainer-hidden': isFilterHidden(2),
                            })}
                        >
                            <InputGroupText>Assigned to</InputGroupText>
                            <UserSelect
                                userIds={filters.assignees.values}
                                operator={filters.assignees.operator}
                                options={optionsList}
                                closeMenuOnSelect
                                onChange={(assignees, operator) =>
                                    onChange({
                                        assignees: {
                                            values: assignees,
                                            operator,
                                        },
                                    })
                                }
                            />
                        </InputGroup>
                        <SelectWithAddon
                            name="Status"
                            className={classNames({
                                'FiltersContainer-hidden': isFilterHidden(3),
                            })}
                            value={filters.status}
                            options={TASK_STATUS_OPTIONS}
                            isMulti
                            onChange={(status: TaskStatus[]) => onChange({ status })}
                        />
                        <InputGroup
                            className={classNames({
                                'FiltersContainer-hidden': isFilterHidden(4),
                            })}
                        >
                            <InputGroupText>Columns</InputGroupText>
                            <KanbanColumnSelect
                                value={filters.lanes}
                                options={lanes}
                                onChange={(ids) => onChange({ lanes: ids })}
                            />
                        </InputGroup>
                    </>
                )}
            </FiltersContainer>
        </div>
    );
};

export default KanbanFilters;
