import { Feature } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';

export type Module =
    | 'CUSTOM_DASHBOARDS'
    | 'IMPORTER'
    | 'MEDIAPLAN'
    | 'LOGBOOK_MANAGEMENT'
    | 'LOGBOOK_BUDGET_SHIFTING'
    | 'LOGBOOK_OPTIMIZATION'
    | 'ALGORITHM'
    | 'ALGORITHM_HISTORY'
    | 'ALGORITHM_PREVIEW'
    | 'ADVANCED_ADMIN_SETTINGS'
    | 'ADVANCED_CAMPAIGN_SETTINGS'
    | 'ADVERTISERS'
    | 'CAMPAIGNS'
    | 'CHANGELOG'
    | 'CREATIVES'
    | 'DYNAMIC_FEEDS'
    | 'BUDGET'
    | 'OPTIMIZATION'
    | 'ANALYTICS_VIEW'
    | 'ANALYTICS_FILTERS_EDIT'
    | 'ANALYTICS_SETTINGS_EDIT'
    | 'CUSTOMER_JOURNEY'
    | 'CUSTOM_ANALYTICS'
    | 'FINANCE_ANALYTICS'
    | 'MANAGEMENT_ANALYTICS'
    | 'TESTING_ANALYTICS'
    | 'MONITORING_ANALYTICS'
    | 'ANALYTICS_INLINE_ACTIONS'
    | 'CDP'
    | 'ROLE_ADVERTISER_TAG'
    | 'ADMIN_FEATURES'
    | 'SENSITIVE_DATA'
    | 'SPEND_DATA'
    | 'USER_MANAGEMENT'
    | 'TASK_MANAGEMENT'
    | 'TASK_BOARD_MANAGEMENT'
    | 'DEFAULT_CHAT'
    | 'CHAT_BETA_TESTING'
    | 'CHAT_AGENTS'
    | 'CHAT_PROMPTS'
    | 'CHAT_MANAGEMENT'
    | 'ANALYTICS_PERSONAL_SECTION_EDIT'
    | 'CUSTOM_FOLDER_EDIT'
    | 'PRODUCT_ACTION_PROBABILITY'
    | 'OBSERVER_MESSAGE_CENTER'
    | 'HELP';

type ModuleConfig = {
    features: { view?: Feature; edit?: Feature };
    dependencies?: { view?: Feature[]; edit?: Feature[] };
    title: string;
    description?: { header: string; content?: string[] };
};

export const MODULE_CONFIG: Record<Module, ModuleConfig> = {
    CUSTOM_DASHBOARDS: {
        features: { view: 'CUSTOM_DASHBOARDS' },
        title: 'Custom dashboards',
    },
    IMPORTER: {
        features: { view: 'IMPORTER' },
        dependencies: {
            view: ['ROLE_CAMPAIGNS_EDIT'],
        },
        title: 'Importer',
    },
    MEDIAPLAN: {
        features: { view: 'MEDIAPLAN_VIEW', edit: 'MEDIAPLAN_EDIT' },
        title: 'Mediaplan',
    },
    LOGBOOK_MANAGEMENT: {
        features: { view: 'LOGBOOK_VIEW', edit: 'LOGBOOK_ADMIN' },
        dependencies: {
            view: ['MEDIAPLAN_VIEW'],
        },
        title: 'All Log Book entries',
    },
    LOGBOOK_BUDGET_SHIFTING: {
        features: { view: 'LOGBOOK_BUDGET_SHIFTING_VIEW' },
        dependencies: {
            view: ['MEDIAPLAN_VIEW'],
        },
        title: 'Budget shifting Log Book entries',
    },
    LOGBOOK_OPTIMIZATION: {
        features: { view: 'LOGBOOK_OPTIMIZATION_VIEW' },
        dependencies: {
            view: ['MEDIAPLAN_VIEW'],
        },
        title: 'Optimization Log Book entries',
    },
    ALGORITHM: {
        features: { view: 'ALGORITHM_VIEW', edit: 'ALGORITHM_EDIT' },
        dependencies: {
            edit: ['ALGORITHM_HISTORY', 'ALGORITHM_PREVIEW'],
        },
        title: 'Algorithm list',
        description: {
            header: 'View & Edit grants permission to:',
            content: [
                'Setup and modify algorithms',
                'See inactive algorithms',
                'Run algorithms',
                'View execution log',
            ],
        },
    },
    ALGORITHM_HISTORY: {
        features: { view: 'ALGORITHM_HISTORY' },
        title: 'Algorithm history',
    },
    ALGORITHM_PREVIEW: {
        features: { view: 'ALGORITHM_PREVIEW' },
        title: 'Algorithm preview',
    },
    ADVANCED_ADMIN_SETTINGS: {
        features: { edit: 'ADVANCED_ADMIN_SETTINGS' },
        title: 'Advanced admin settings',
        description: {
            header: 'Allows to:',
            content: [
                'Save system reports in Analytics',
                'Add system dimensions and filters to reports',
                'Enable/disable Automated IO spend in Vendor',
            ],
        },
    },
    ADVANCED_CAMPAIGN_SETTINGS: {
        features: { view: 'ADVANCED_CAMPAIGN_SETTINGS', edit: 'ADVANCED_CAMPAIGN_SETTINGS_EDIT' },
        title: 'Advanced campaign settings',
        description: {
            header: 'Allows access to:',
            content: ['Cost Sources', 'Scoring', 'Products', 'Custom metrics', 'Analytics naming'],
        },
    },
    ADVERTISERS: {
        features: { view: 'ROLE_ADVERTISERS_VIEW', edit: 'ROLE_ADVERTISERS_EDIT' },
        title: 'Advertisers',
    },
    CAMPAIGNS: {
        features: { view: 'ROLE_CAMPAIGNS_VIEW', edit: 'ROLE_CAMPAIGNS_EDIT' },
        title: 'Campaigns',
    },
    OBSERVER_MESSAGE_CENTER: {
        features: { edit: 'MESSAGE_CENTER_EDIT' },
        title: 'DeeplyAI Cockpit',
    },
    CHANGELOG: {
        features: { view: 'ROLE_CHANGELOG_VIEW' },
        title: 'Changelog',
    },
    CREATIVES: {
        features: { view: 'ROLE_CREATIVES_VIEW', edit: 'ROLE_CREATIVES_EDIT' },
        title: 'Creatives',
    },
    DYNAMIC_FEEDS: {
        features: { view: 'DYNAMIC_FEEDS', edit: 'DYNAMIC_FEEDS_EDIT' },
        title: 'Dynamic feeds',
    },
    BUDGET: {
        features: { view: 'BUDGET' },
        title: 'Budget',
        description: {
            header: 'Allows access to Budget section',
        },
    },
    OPTIMIZATION: {
        features: { view: 'OPTIMIZATION' },
        title: 'Optimization',
        description: {
            header: 'Allows access to Optimization section',
        },
    },
    ANALYTICS_VIEW: {
        features: { view: 'ROLE_ANALYTICS_VIEW' },
        title: 'Analytics',
    },
    ANALYTICS_FILTERS_EDIT: {
        features: { edit: 'ANALYTICS_FILTERS_EDIT' },
        title: 'Analytics filters',
    },
    ANALYTICS_SETTINGS_EDIT: {
        features: { edit: 'ANALYTICS_SETTINGS_EDIT' },
        dependencies: {
            edit: ['ANALYTICS_FILTERS_EDIT'],
        },
        title: 'Analytics settings',
    },
    ANALYTICS_PERSONAL_SECTION_EDIT: {
        features: { edit: 'ANALYTICS_PERSONAL_SECTION_EDIT' },
        title: 'Analytics Personal Section',
    },
    CUSTOMER_JOURNEY: {
        features: { view: 'ROLE_CUSTOMER_JOURNEY_VIEW' },
        title: 'Customer Journey',
    },
    CUSTOM_ANALYTICS: {
        features: { view: 'CUSTOM_ANALYTICS' },
        title: 'Custom Analytics',
    },
    FINANCE_ANALYTICS: {
        features: { view: 'FINANCE_ANALYTICS' },
        title: 'Finance',
    },
    MANAGEMENT_ANALYTICS: {
        features: { view: 'MANAGEMENT_ANALYTICS' },
        title: 'Management',
    },
    TESTING_ANALYTICS: {
        features: { view: 'TESTING_ANALYTICS' },
        title: 'Testing',
    },
    MONITORING_ANALYTICS: {
        features: { view: 'MONITORING_ANALYTICS' },
        title: 'Monitoring',
    },
    ANALYTICS_INLINE_ACTIONS: {
        features: { edit: 'ANALYTICS_INLINE_ACTIONS' },
        title: 'Analytics Inline Actions',
        description: {
            header: 'Allows to edit campaigns directly from Analytics.',
        },
    },
    CDP: {
        features: { view: 'ROLE_CDP_VIEW', edit: 'ROLE_CDP_EDIT' },
        title: 'Dashboard / Segments',
    },
    ROLE_ADVERTISER_TAG: {
        features: { view: 'ROLE_ADVERTISER_TAG_VIEW', edit: 'ROLE_ADVERTISER_TAG_EDIT' },
        title: 'AdvertiserTag',
    },
    ADMIN_FEATURES: {
        features: { view: 'ROLE_ADMINPANEL_VIEW', edit: 'ROLE_ADMINPANEL_EDIT' },
        title: 'Other Admin Features',
        description: {
            header: 'Allows access to Admin section:',
            content: [
                'Insight queries',
                'Categorization',
                'Vendors',
                'Creative settings',
                'Admin panel',
                'File sharing',
                'Report management',
                'Algorithm manager',
                'DeeplyAI manager',
                'DeeplyAI internal agents',
            ],
        },
    },
    SENSITIVE_DATA: {
        features: { view: 'ROLE_ADMIN_DATA_VIEW' },
        title: 'Sensitive data',
        description: {
            header: 'Allows to view metrics:',
            content: ['Profit', 'Margin'],
        },
    },
    SPEND_DATA: {
        features: { view: 'SPEND_DATA' },
        title: 'Sensitive data (Spend)',
        description: {
            header: 'Allows to view metrics:',
            content: ['Spend', 'Spend vattable', 'Search ads fee', 'Tracking fee'],
        },
    },
    USER_MANAGEMENT: {
        features: { edit: 'USER_MANAGEMENT' },
        title: 'User Management',
    },
    TASK_MANAGEMENT: {
        features: { edit: 'TASK_EDIT' },
        title: 'Task management',
        description: {
            header: 'Allows to create, edit and move tasks in a board',
        },
    },
    TASK_BOARD_MANAGEMENT: {
        features: { edit: 'TASK_BOARD_EDIT' },
        dependencies: { edit: ['TASK_EDIT'] },
        title: 'Board management',
        description: {
            header: 'Allows to create task boards and manage columns inside a board',
        },
    },
    DEFAULT_CHAT: {
        title: 'Default chat',
        features: { view: 'CHATBOT_DEFAULT_VIEW' },
    },
    CHAT_BETA_TESTING: {
        title: 'Chat beta testing',
        features: {
            view: 'CHATBOT_BETA_TESTING',
        },
    },
    CHAT_AGENTS: {
        features: {
            view: 'CHATBOT_AGENTS_VIEW',
        },
        title: 'Agents',
    },
    CHAT_PROMPTS: {
        features: {
            view: 'CHATBOT_PROMPTS_VIEW',
        },
        title: 'Prompts',
    },
    CHAT_MANAGEMENT: {
        features: {
            view: 'CHATBOT_MANAGEMENT_VIEW',
            edit: 'CHATBOT_MANAGEMENT_EDIT',
        },
        title: 'Management',
        description: {
            header: 'Allows to view and manage different chat features',
            content: ['Agents', 'Prompts', 'Context prompts'],
        },
    },
    PRODUCT_ACTION_PROBABILITY: {
        features: {
            view: 'PRODUCT_ACTION_PROBABILITY',
        },
        title: 'Products',
    },
    CUSTOM_FOLDER_EDIT: {
        features: {
            edit: 'CUSTOM_FOLDER_EDIT',
        },
        title: 'Custom folders',
    },
    HELP: {
        features: {
            view: 'HELP_VIEW',
            edit: 'HELP_EDIT',
        },
        title: 'Help',
    },
};

export const MODULE_GROUPS: Record<string, Module[]> = {
    Dashboards: ['CUSTOM_DASHBOARDS'],
    Campaigns: [
        'ADVERTISERS',
        'CAMPAIGNS',
        'IMPORTER',
        'MEDIAPLAN',
        'CHANGELOG',
        'ADVANCED_CAMPAIGN_SETTINGS',
    ],
    'DeeplyAI Cockpit': ['OBSERVER_MESSAGE_CENTER'],
    'Log Book': ['LOGBOOK_MANAGEMENT', 'LOGBOOK_BUDGET_SHIFTING', 'LOGBOOK_OPTIMIZATION'],
    Algorithms: ['ALGORITHM', 'ALGORITHM_HISTORY', 'ALGORITHM_PREVIEW'],
    Budget: ['BUDGET'],
    Optimization: ['OPTIMIZATION'],
    Creatives: ['CREATIVES', 'DYNAMIC_FEEDS'],
    Audiences: ['CDP', 'PRODUCT_ACTION_PROBABILITY', 'ROLE_ADVERTISER_TAG'],
    'Customer Journey': ['CUSTOMER_JOURNEY'],
    Analytics: [
        'CUSTOM_ANALYTICS',
        'FINANCE_ANALYTICS',
        'MANAGEMENT_ANALYTICS',
        'TESTING_ANALYTICS',
        'MONITORING_ANALYTICS',
        'ANALYTICS_VIEW',
        'ANALYTICS_INLINE_ACTIONS',
        'ANALYTICS_FILTERS_EDIT',
        'ANALYTICS_SETTINGS_EDIT',
        'ANALYTICS_PERSONAL_SECTION_EDIT',
        'CUSTOM_FOLDER_EDIT',
    ],
    'Task Boards': ['TASK_MANAGEMENT', 'TASK_BOARD_MANAGEMENT'],
    Admin: ['USER_MANAGEMENT', 'ADMIN_FEATURES', 'ADVANCED_ADMIN_SETTINGS'],
    Data: ['SENSITIVE_DATA', 'SPEND_DATA'],
    'Chat Bot': ['DEFAULT_CHAT', 'CHAT_AGENTS', 'CHAT_BETA_TESTING', 'CHAT_PROMPTS', 'CHAT_MANAGEMENT'],
    Help: ['HELP'],
};

export const MODULE_CONFIG_BY_FEATURE: { [key in Feature]?: ModuleConfig } = Object.fromEntries(
    Object.values(MODULE_CONFIG).flatMap((config): [Feature, ModuleConfig][] =>
        [config.features.view, config.features.edit].filter(isDefined).map((key) => [key, config])
    )
);

export const featureName = (feature: Feature) => {
    const module = MODULE_CONFIG_BY_FEATURE[feature];
    if (module) {
        return `${module.title}${feature === module.features.edit ? ' editing' : ''}`;
    }
    return feature;
};
