import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Nav, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import classNames from 'classnames';
import RealTimeSyncPanel from 'platform/adminPanel/components/SyncJournal/RealTimeSyncPanel';
import GlobalSearch from 'platform/app/components/GlobalSearch/GlobalSearch';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import CompanyLogo from 'platform/common/components/CompanyLogo/CompanyLogo';
import ErrorBoundary from 'platform/common/containers/ErrorBoundary/ErrorBoundary';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useIsMobile } from 'platform/common/hooks/useIsMobile';
import ObserverMessageNotificationPanel from 'platform/observerMessageCenter/ObserverMessageNotificationPanel';
import { toggleMobileSidebar } from '../Sidebar/Sidebar';
import UserButton from '../UserButton/UserButton';
import ZoomButton from '../ZoomButton/ZoomButton';

interface Props {
    children?: ReactNode;
    isAdmin: boolean;
    isLoading: boolean;
}

const AppHeader = ({ children, isAdmin, isLoading }: Props) => {
    const isMobile = useIsMobile();
    const canAccessMessageCenter = useFeature('MESSAGE_CENTER_EDIT');
    const demoMode = useSelector(authSelectors.isDemoModeEnabled);

    return (
        <header
            className={classNames('app-header navbar rt-sticky-thead-push', {
                'mobile pe-3': isMobile,
            })}
        >
            <NavbarToggler onClick={toggleMobileSidebar} className="main-sidebar-toggler">
                &#9776;
            </NavbarToggler>

            {!isMobile && (
                <NavbarBrand className="header-logo mx-3" href={window.basename || '/'}>
                    <CompanyLogo />
                </NavbarBrand>
            )}

            <div className="ps-2" />
            <div className="app-header-children">{children}</div>

            <Nav className="ms-auto" navbar>
                {isAdmin && (
                    <>
                        <NavItem className="px-2">
                            <GlobalSearch disabled={isLoading} />
                        </NavItem>
                        <NavItem className="px-2">
                            <ErrorBoundary>
                                <RealTimeSyncPanel />
                            </ErrorBoundary>
                        </NavItem>
                    </>
                )}
                {canAccessMessageCenter && !demoMode && (
                    <NavItem className="px-2">
                        <ErrorBoundary>
                            <ObserverMessageNotificationPanel />
                        </ErrorBoundary>
                    </NavItem>
                )}
                <NavItem className="px-2">
                    <ZoomButton />
                </NavItem>
                <NavItem className="ps-3">
                    <UserButton />
                </NavItem>
            </Nav>
        </header>
    );
};

export default AppHeader;
