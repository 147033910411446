import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { FilterBy, LogDateFilterStrategy } from 'platform/campaign/logBook/logBook.constant';
import { LogbookFilter, searchLogBook } from 'platform/campaign/logBook/logbook.service';
import { useLogBookAccess } from 'platform/campaign/logBook/useLogBookAccess';
import Popover from 'platform/common/components/Popover/Popover';
import { usePromise } from 'platform/common/hooks/usePromise';
import { stringifyParams } from 'platform/common/utils/url.util';
import { ReportInteraction } from '../analytics.types';
import { analyticsSelectors } from '../ducks/analytics.duck';
import { getComponentInteractions } from './useReport';

const FILTER_MAP: Record<keyof LogbookFilter, string> = {
    dateFrom: 'date_from',
    dateTo: 'date_to',
    productCategory: 'product_category',
    productLabel: 'product_label',
    productSubLabel: 'product_sub_label',
    flightLabel: 'flight_label',
    countryLabel: 'country_labels',
    status: '',
    category: '',
    mediaplanId: '',
    mediaInsertionId: '',
    dateFilterStrategy: '',
    advertiserId: '',
};

export const insertInteractionFilters = (filter: LogbookFilter, interactions: ReportInteraction[]) => {
    let newFilter = { ...filter };

    Object.entries(FILTER_MAP).forEach(([filterKey, interactionKey]) => {
        const interaction = interactions?.find((i) => i.key === interactionKey);

        if (interaction) {
            newFilter = { ...newFilter, [filterKey]: interaction.value };
        }
    });

    return newFilter;
};

interface Props {
    mediaInsertionId?: number;
    mediaplanId?: number;
    advertiserId: number;
    dateFrom?: string;
    dateTo?: string;
}

const LogBookLink = ({ mediaInsertionId, mediaplanId, advertiserId, dateFrom, dateTo }: Props) => {
    const { showLogBookLink } = useSelector(analyticsSelectors.settings);
    const components = useSelector(analyticsSelectors.interactionComponents, isEqual);
    const logBookAccess = useLogBookAccess();
    /* should filter by media insertion only if both mediaplan & media insertion are selected */
    const filterByMediaplanOrInsertion = !!(mediaInsertionId || mediaplanId);
    const [fetchedFilter, setFetchedFilter] = useState<LogbookFilter>({ advertiserId });

    const logbookFilter = useMemo(() => {
        const interactions = getComponentInteractions(components);

        const filter: LogbookFilter = {
            advertiserId,
            dateFrom,
            dateTo,
            dateFilterStrategy: filterByMediaplanOrInsertion ? undefined : LogDateFilterStrategy.NOTE_PERIOD,
            mediaplanId: mediaplanId && !mediaInsertionId ? [mediaplanId] : [],
            mediaInsertionId: mediaInsertionId ? [mediaInsertionId] : [],
        };

        return insertInteractionFilters(filter, interactions);
    }, [
        components,
        advertiserId,
        dateFrom,
        dateTo,
        mediaInsertionId,
        mediaplanId,
        filterByMediaplanOrInsertion,
    ]);

    const [{ data: logsCount }] = usePromise(
        0,
        async () => {
            const canFetch = !isEqual(fetchedFilter, logbookFilter) && logBookAccess.isAllowed;

            if (canFetch) {
                const logs = await searchLogBook(logbookFilter);
                setFetchedFilter(logbookFilter);
                return logs.length;
            }
            return 0;
        },
        [logbookFilter]
    );

    const url = `/logs/logbook?${stringifyParams({
        ...logbookFilter,
        filterBy: filterByMediaplanOrInsertion ? FilterBy.MEDIAPLAN_OR_INSERTION : FilterBy.NOTE_PERIOD,
    })}`;

    if (!logsCount || showLogBookLink === false) {
        return null;
    }

    return (
        <Popover
            placement="bottom-end"
            trigger="hover"
            renderPopover={() => <div className="p-2">{`${logsCount} Log Book entries are available`}</div>}
        >
            <div role="button" tabIndex={-1} title="Log Book info" className="me-4" style={{ marginTop: 2 }}>
                <Link target="_blank" to={url} className="fw-bold text-decoration-none">
                    {logsCount}
                    <i className="fal fa-book ms-2" />
                </Link>
            </div>
        </Popover>
    );
};

export default LogBookLink;
