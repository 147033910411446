import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';
import actionStatsReportRoutes from 'platform/actionStatsReport/actionStatsReport.routes';
import adminPanelRoutes from 'platform/adminPanel/adminPanel.routes';
import MaintenanceModeNotification from 'platform/adminPanel/components/SystemSettings/MaintenanceModeNotification';
import { useHelp } from 'platform/adminPanel/hooks/useHelp';
import advertiserTagRoutes from 'platform/advertiserTag/advertiserTag.routes';
import advertiserRoutes from 'platform/advertisers/advertisers.routes';
import { fetchContactInformation } from 'platform/advertisers/services/advertiser.service';
import algorithmsRoutes from 'platform/algorithms/algorithms.routes';
import analyticsRoutes from 'platform/analytics/analytics.routes';
import { useAppContext } from 'platform/app/AppContext';
import { NavigationItem, RoutingItem } from 'platform/app/app.types';
import Intro from 'platform/app/components/Intro/Intro';
import { useDefaultRedirect } from 'platform/app/components/Sidebar/useDefaultRedirect';
import { useNavigationItems } from 'platform/app/components/Sidebar/useNavigationItems';
import { useAdvertiserContext } from 'platform/app/hooks/useAdvertiserContext';
import advertiserSettingsRoutes from 'platform/campaign/advertiserManagement/advertiserSettings.routes';
import campaignRoutes from 'platform/campaign/campaign.routes';
import implementationTestRoute from 'platform/campaign/implementationTest/implementationTest.routes';
import logBookRoute from 'platform/campaign/logBook/logBook.routes';
import strategyRoutes from 'platform/campaign/strategy/strategy.routes';
import categoryRoutes from 'platform/category/category.routes';
import channelRoutes from 'platform/channels/channel.routes';
import { fetchChannels } from 'platform/channels/channel.service';
import chatRoutes from 'platform/chat/chat.routes';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Unauthorized from 'platform/common/components/Unauthorized/Unauthorized';
import ErrorBoundary from 'platform/common/containers/ErrorBoundary/ErrorBoundary';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { classifierActions } from 'platform/common/ducks/commonClassifiers.duck';
import { contactActions } from 'platform/common/ducks/contacts.duck';
import { useIsMobile } from 'platform/common/hooks/useIsMobile';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useAdvertiserUrlSync } from 'platform/common/hooks/useUrlSync/useAdvertiserUrlSync';
import contactPageRoutes from 'platform/contacts/contacts.routes';
import creativesRoutes from 'platform/creatives/creatives.routes';
import folderAccessRoutes from 'platform/customReports/FolderAccess/folderAccess.routes';
import dashboardRoutes from 'platform/dashboard/dashboard.routes';
import { dataExplorerRoute } from 'platform/dataExplorer/dataExplorer.route';
import embeddedReportRoute from 'platform/embeddedRerport/embeddedReport.route';
import fileSharingRoutes from 'platform/fileSharing/fileSharing.routes';
import insightRoutes from 'platform/insight/insight.routes';
import mediaplanPageRoutes from 'platform/mediaplan/mediaplan.routes';
import naturalLanguageRoutes from 'platform/naturalLanguageSentiments/naturalLanguage.routes';
import observerRoutes from 'platform/observer/observer.routes';
import observerMessageCenterRoutes from 'platform/observerMessageCenter/observerMessageCenter.routes';
import { productActionProbabilityRoute } from 'platform/productActionProbability/productActionProbability.routes';
import segmentRoutes from 'platform/segments/segments.routes';
import { taskBoardRoutes } from 'platform/task/taskRoutes';
import { fetchSeat } from 'platform/userManagement/services/userManagement.service';
import userManagementRoutes from 'platform/userManagement/userManagement.routes';
import vendorsRoutes from 'platform/vendors/vendors.routes';
import { fetchVendors } from 'platform/vendors/vendors.service';
import { profile, underConstruction } from '../../app.routes';
import { authSelectors } from '../../ducks/auth.duck';
import AppHeader from '../AppHeader/AppHeader';
import ChatSwitch from '../ChatSwitch/ChatSwitch';
import NotFoundPage from '../NotFound/NotFound';
import Sidebar from '../Sidebar/Sidebar';

const routes: RoutingItem[] = [
    ...analyticsRoutes,
    ...dashboardRoutes,
    ...advertiserTagRoutes,
    ...contactPageRoutes,
    ...adminPanelRoutes,
    ...actionStatsReportRoutes,
    ...mediaplanPageRoutes,
    ...userManagementRoutes,
    ...segmentRoutes,
    ...advertiserRoutes,
    ...creativesRoutes,
    ...campaignRoutes,
    implementationTestRoute,
    logBookRoute,
    ...taskBoardRoutes,
    ...advertiserSettingsRoutes,
    ...categoryRoutes,
    ...channelRoutes,
    ...fileSharingRoutes,
    profile,
    ...strategyRoutes,
    ...observerRoutes,
    ...insightRoutes,
    ...vendorsRoutes,
    ...algorithmsRoutes,
    ...naturalLanguageRoutes,
    ...chatRoutes,
    ...observerMessageCenterRoutes,
    ...folderAccessRoutes,
    embeddedReportRoute,
    dataExplorerRoute,
    underConstruction,
    productActionProbabilityRoute,
];

const getAdvertiserOwnedPaths = (item: NavigationItem): string[] => [
    ...(item.isAdvertiserOwned && item.path ? [item.path] : []),
    ...(item.children ?? []).flatMap(getAdvertiserOwnedPaths),
];

const App = () => {
    const [appChildren] = useAppContext();
    const { updateHelpState } = useHelp();
    const location = useLocation();
    const hasFeature = useSelector(authSelectors.hasFeatureFn);
    const hasAnalyticsAuthority = useSelector(authSelectors.hasAnalyticsAuthority);
    const account = useSelector(authSelectors.ready.account);
    const activeAdvertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const dispatch = useDispatch();
    const isAdmin = hasFeature('ROLE_ADMINPANEL_VIEW');
    const isMobile = useIsMobile();

    const { navigationItems, availablePaths } = useNavigationItems();
    useDefaultRedirect(availablePaths);
    const advertiserOwnedPaths = navigationItems.flatMap(getAdvertiserOwnedPaths);

    const [{ data: seatData }] = usePromise(null, () => fetchSeat(account.seatId), []);

    useEffect(() => {
        document.title = seatData?.platformName || 'Deep Advertising Platform';
    }, [seatData?.platformName]);

    useEffect(() => {
        const loadCommonClassifiers = async () => {
            const [channels, vendors] = await Promise.all([fetchChannels(), fetchVendors()]);
            dispatch(classifierActions.setCommonClassifiers({ channels, vendors }));
        };

        loadCommonClassifiers();
        updateHelpState();
    }, []);

    useEffect(() => {
        (async () => {
            const contacts = await fetchContactInformation(activeAdvertiser.id);
            dispatch(contactActions.setContacts(contacts));
        })();
    }, [activeAdvertiser.id]);

    const { advertiserContextLoading, changeAdvertiser } = useAdvertiserContext(activeAdvertiser);

    useAdvertiserUrlSync();

    const handleRedirect = () => dispatch(push(availablePaths?.[0] ?? '/'));

    const handleAdvertiserSelect = (selectedAdvertisers: AdvertiserOption[]) => {
        changeAdvertiser(selectedAdvertisers);
        if (advertiserOwnedPaths.some((path) => location.pathname.startsWith(path))) {
            // when switching advertisers while some advertiser-owned item is open (like a task-board),
            // redirect to the first item of newly selected advertiser
            handleRedirect();
        }
    };

    return (
        <div className="app" onDragOver={(e) => e.preventDefault()}>
            {advertiserContextLoading && <OverlayLoader />}
            <Intro />
            <Sidebar
                navigationItems={navigationItems}
                isMobile={isMobile}
                isLoading={advertiserContextLoading}
            />
            <AppHeader isAdmin={isAdmin} isLoading={advertiserContextLoading}>
                {appChildren.appHeaderChildren && (
                    <appChildren.appHeaderChildren onAdvertisersChange={handleAdvertiserSelect} />
                )}
            </AppHeader>
            <main className="main">
                <div className="position-relative">
                    <div data-testid="mainContainer" className="main-container">
                        <MaintenanceModeNotification />
                        {!advertiserContextLoading && (
                            <Routes>
                                {routes.map((route) => {
                                    const isAuthorized = route.requiresFeature
                                        ? hasFeature(route.requiresFeature) ||
                                          (route.requiresFeature === 'ROLE_ANALYTICS_VIEW' &&
                                              hasAnalyticsAuthority())
                                        : true;
                                    return (
                                        <Route
                                            key={route.path}
                                            path={`${route.path}/*`}
                                            element={
                                                <ErrorBoundary>
                                                    {!isAuthorized && <Unauthorized />}
                                                    {isAuthorized && !route.lazy && (
                                                        <route.component
                                                            onAdvertisersChange={changeAdvertiser}
                                                            onRedirect={handleRedirect}
                                                        />
                                                    )}
                                                    {isAuthorized && route.lazy && (
                                                        <Suspense key={route.path} fallback={OverlayLoader}>
                                                            <route.component
                                                                onAdvertisersChange={changeAdvertiser}
                                                                onRedirect={handleRedirect}
                                                            />
                                                        </Suspense>
                                                    )}
                                                </ErrorBoundary>
                                            }
                                        />
                                    );
                                })}
                                <Route element={<NotFoundPage />} />
                            </Routes>
                        )}
                    </div>
                </div>
                <ChatSwitch />
            </main>
        </div>
    );
};

export default App;
